html, body {
  min-height: 100vh;
  overflow: auto;
}

body > #root {
  min-height: 100vh;
  overflow: auto;
}

.App {
  text-align: center;
}

.MuiDataGrid-main div[style*="pointer-events: none;"] {
  opacity: 0;
}

.rc-notification {
  top: initial !important;
  left: initial !important;
  bottom: 65px !important;
  right: 45px !important;
  text-align: right;
}

.rc-notification > div > div {
  display: block;
  float: right;
  clear: both;
}
