.matching-table {
	width: 100%;
	display: table;
}

.matching-table > thead {
	background-color: black;
}

.matching-table > thead > tr > th {
	color: #fff !important;
}


.matching-table > thead > tr > th > div {
	background-color: #c0c0c0;
	color: #232323;
	border-radius: 5px;
	padding: 0 10px;

	display: inline-block;

	text-align: center;
}

.matching-table > tbody > tr > td.null {
	background-color: #00000030;
}

.matching-table > tbody > tr > td {
	border-right: 1px solid #c0c0c0;
}

.matching-table > tbody > tr > td > div {
	word-break: break-all;

	max-height: 120px;

	overflow-x: hidden;
	overflow-y: auto;
}

.matching-table > tbody > tr > td:first-child {
	font-weight: bold;
}

.matching-table > tbody > tr > td:last-child {
	border-right: 0;
}


.matching-table ~ .MuiStack-root {
	text-align: center;
}

.matching-table ~ .MuiStack-root > button.left {
	float: left;
}

.matching-table ~ .MuiStack-root > button.left {
	margin-right: auto;
}

.matching-table ~ .MuiStack-root > button.right {
	float: right;
}
.matching-table ~ .MuiStack-root > div.right {
	margin-left: auto;
}

.MatchingSelectTask {
	display: block;
	max-width: 450px;
	margin: 90px auto 0 auto;
}


.matching-table-container {
	max-width: calc( 100vw - 48px );
	padding-bottom: 10px;
	overflow: hidden;
	overflow-x: auto;

	margin-bottom: 230px;
}

.matching-table-container ~ footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	padding: 0 48px;
	background-color: #fff;
	box-sizing: border-box;
	border-top: 1px solid #c0c0c0;
	box-shadow: 0 0 15px #c0c0c0a0;
}

.matching-table-image {
	max-width: 100px;
	height: 80px;
	object-fit: cover;
}

td:has(> .green-cell) {
	background-color: #00ff0030;
}

td:has(> .red-cell) {
	background-color: #ff000030;
}
