.AdminIndex.MuiContainer-root .MuiBox-root .MuiLink-root {
	margin: 10px auto;
	max-width: 450px;
	display: block;

	padding: 10px 20px;
	box-sizing: border-box;
	box-shadow: 1px 2px 5px #0009;

	border-radius: 3px;
}


.AdminIndex.MuiContainer-root .MuiBox-root .MuiLink-root > p {
	opacity: 0.6;
}


.row-changed {
	background-color: #00800050;
}

.row-changed:hover {
	background-color: #008000a0 !important;
}
